import React, { useEffect } from "react";
import { LOCATION, animationDuration } from "../utils/constant.util";
import { useResize } from "../context/resizeContext";
import { Fade } from "react-awesome-reveal";
import styles from "../styles/location.module.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
export default function Location() {
  const { width } = useResize();
  return (
    <Fade
      direction="bottom"
      cascade
      triggerOnce
      duration={animationDuration(width)}
    >
      <section id="location" className={styles.locationBoth}>
        {LOCATION.map((loc) => (
          <div className={styles.tabContenContainer} key={loc.tabBtnName}>
            <img src={loc.bckg} alt={loc.tabBtnName} />
            <div className={styles.contactDetails}>
              <div className={styles.contactDetailsContent}>
                <div className={styles.contactDesc}>
                  <LocationOnIcon />
                  :&nbsp; &nbsp;
                  <span className={styles.bold}>{loc.locationName}</span>
                </div>
                <div className={styles.contactDesc}>
                  <a href={`tel:${loc.mobileName}`}>
                    <PhoneIcon />
                    :&nbsp; &nbsp;
                    <span className={styles.bold}>{loc.mobileName}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </Fade>
  );
}
