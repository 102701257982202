import MARQ from "../assets/images/marq.png";
import styles from "../styles/footer.module.scss";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
export default function Footer() {
  return (
    <footer className={styles.mainFooter}>
      <div className={styles.logo}>
        <img src={MARQ} alt="Company Name" />
      </div>
      <div className={styles.social}>
        <div className={styles.slogan}>Stay Ahead!</div>
        <div className={styles.socialNetwork}>
          <a href="https://www.instagram.com/marq.52/" target="_blank">
            <InstagramIcon />
          </a>
          <a href="https://www.linkedin.com/company/marq52/" target="_blank">
            <LinkedIcon />
          </a>
          <a href="https://www.facebook.com/52.Marq" target="_blank">
            <FacebookIcon />
          </a>
        </div>
      </div>
      <div className="tradeMark">&copy; {new Date().getFullYear()} MARQ52</div>
    </footer>
  );
}
