import Slider from "react-slick";
import { CLOUDNAME, SERVICES, animationDuration } from "../utils/constant.util";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../styles/services.module.scss";
import classNames from "classnames";
import Cascading from "./cascading";
import { Fade } from "react-awesome-reveal";
import { useResize } from "../context/resizeContext";
import React, { useState } from "react";
import { Image } from "cloudinary-react";
import { CircularProgress } from "@mui/material";

const { changeOrder, image, loading } = styles;

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

const ShimmerImage = ({ cloudName, publicId }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className={styles.shimmerContainer}>
      {isLoading && <CircularProgress style={{ color: "white", height: 70 }} />}
      <Image
        cloudName={cloudName}
        publicId={publicId}
        onLoad={handleLoad}
        className={`${image} ${isLoading ? loading : ""}`}
      />
    </div>
  );
};

export default function Services() {
  const { width } = useResize();
  return (
    <Fade
      direction="bottom"
      cascade
      triggerOnce
      duration={animationDuration(width)}
    >
      <section id="services" className={styles.services}>
        <Cascading />
        {SERVICES.map((service) => (
          <Fade
            direction="left"
            cascade
            triggerOnce
            key={service.title}
            duration={animationDuration(width)}
          >
            <div
              className={classNames(styles.servicesCont, {
                [changeOrder]: service.ordering,
              })}
              id={service.id}
            >
              <div className={styles.servicesImages}>
                <Slider {...settings}>
                  {service.images.map((image, index) => (
                    <React.Fragment key={index}>
                      <ShimmerImage cloudName={CLOUDNAME} publicId={image} />
                    </React.Fragment>
                  ))}
                </Slider>
              </div>
              <div className={styles.servicesText}>
                <h3>{service.title}</h3>
                <p>{service.text}</p>
              </div>
            </div>
          </Fade>
        ))}
      </section>
    </Fade>
  );
}
