import styles from "../styles/header.module.scss";
import { HEADER_NAVIGATION, scrollToSection } from "../utils/constant.util";
import Logo from "../assets/images/logo.png";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

const { bckg, hide } = styles;

export default function Home() {
  const [shadow, setShadow] = useState(false);
  const scrollHandler = (e) => {
    const body = document.body;
    const html = document.documentElement;
    const height = Math.max(
      body.getBoundingClientRect().height,
      html.getBoundingClientRect().height
    );
    const logo = document.querySelector('[data-logo="Logo"]');
    if (window.scrollY >= 25) {
      setShadow(true);
    } else {
      setShadow(false);
    }
    if (window.scrollY + window.innerHeight > height - 200) {
      if (!logo.classList.contains(hide)) {
        logo.classList.add(hide);
      }
    } else {
      if (logo.classList.contains(hide)) {
        logo.classList.remove(hide);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);
  return (
    <header
      className={classNames(styles.header, { [bckg]: shadow })}
      id="header"
    >
      <a href="/" className={styles.logo} data-logo="Logo">
        <img src={Logo} alt="Logo" />
      </a>
      <input className={styles.menuBtn} type="checkbox" id="menu-btn" />
      <label
        className={styles.menuIcon}
        htmlFor="menu-btn"
        style={{ color: "white" }}
      >
        <span className={styles.navIcon} />
      </label>
      <ul className={styles.menu}>
        {Object.keys(HEADER_NAVIGATION).map((nav) => (
          <li key={nav}>
            <a
              href="#!"
              onClick={() => scrollToSection(HEADER_NAVIGATION[nav])}
            >
              {nav}
            </a>
          </li>
        ))}
      </ul>
    </header>
  );
}
