import Header from "./views/header";
import Home from "./views/home";
import About from "./views/about";
import Services from "./views/services";
import Testimonials from "./views/testimonials";
import Contact from "./views/contact";
import Footer from "./views/footer";
import Location from "./views/location";
import "./App.css";
import { ResizeProvider } from "./context/resizeContext";
import { CloudinaryContext } from "cloudinary-react";

function App() {
  return (
    <CloudinaryContext cloudName="dnxniyxsq">
      <ResizeProvider>
        <div className="App">
          <Header />
          <Home />
          <About />
          <Services />
          {/* <Testimonials /> */}
          <Contact />
          <Location />
          <Footer />
        </div>
      </ResizeProvider>
    </CloudinaryContext>
  );
}

export default App;
