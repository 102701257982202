import { Fade } from "react-awesome-reveal";
import { animationDuration } from "../utils/constant.util";
import { useResize } from "../context/resizeContext";
import styles from "../styles/contact.module.scss";
export default function Contact() {
  const { width } = useResize();
  return (
    <Fade
      direction="bottom"
      cascade
      triggerOnce
      duration={animationDuration(width)}
    >
      <section id="contact" className={styles.contact}>
        <h2>Empower your brand's success</h2>
        <a href="mailto:yusuf@marq52.co.uk">Contact Us</a>
      </section>
    </Fade>
  );
}
