import { Fade } from "react-awesome-reveal";
import styles from "../styles/cascading.module.scss";
import {
  CASCADING,
  animationDuration,
  scrollToSection,
} from "../utils/constant.util";
import { useResize } from "../context/resizeContext";
export default function Cascading() {
  const { width } = useResize();
  return (
    <>
      <div className={styles.hexagonContainer}>
        {CASCADING.map((cascade) => (
          <Fade
            direction="bottom"
            cascade
            triggerOnce
            key={cascade.title}
            duration={animationDuration(width)}
          >
            <a href="#!" onClick={() => scrollToSection(cascade.link)}>
              <div className={styles.hexagon}>{cascade.title}</div>
            </a>
          </Fade>
        ))}
      </div>
    </>
  );
}
