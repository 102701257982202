import { Fade, Slide } from "react-awesome-reveal";
import styles from "../styles/about.module.scss";
import { ABOUT, animationDuration } from "../utils/constant.util";
import { useResize } from "../context/resizeContext";
export default function About() {
  const { width } = useResize();
  return (
    <Fade
      direction="bottom"
      cascade
      triggerOnce
      duration={animationDuration(width)}
    >
      <section id="about" className={styles.about}>
        {ABOUT.map((abt) => (
          <Slide
            direction="left"
            key={abt.heading}
            triggerOnce
            duration={animationDuration(width)}
          >
            <div>
              <h2>{abt.heading}</h2>
              <p>{abt.title}</p>
            </div>
          </Slide>
        ))}
      </section>
    </Fade>
  );
}
