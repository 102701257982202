import { createContext, useContext, useEffect, useState } from "react";

const ResizeContext = createContext();

export const ResizeProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ResizeContext.Provider value={dimensions}>
      {children}
    </ResizeContext.Provider>
  );
};

export const useResize = () => {
  const context = useContext(ResizeContext);
  if (!context) {
    throw new Error("useResize must be used within a ResizeProvider");
  }
  return context;
};
