import Slider from "react-slick";
import { CLOUDNAME, HOME } from "../utils/constant.util";
import styles from "../styles/home.module.scss";
import React from "react";
import { Image } from "cloudinary-react";

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: "linear",
  autoplay: true,
};

export default function Home() {
  return (
    <section id="home" className={styles.mainHome}>
      <Slider {...settings}>
        {HOME.map((home, index) => (
          <React.Fragment key={index}>
            <Image cloudName={CLOUDNAME} publicId={home} />
          </React.Fragment>
        ))}
      </Slider>
      <div className={styles.mainDiv}>
        <h1>Stay Ahead!</h1>
        <h2>
          Create<span className={styles.dot}></span>Inspire
          <span className={styles.dot}></span>
          Innovate
        </h2>
      </div>
    </section>
  );
}
