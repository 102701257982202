export const CLOUDNAME = "dnxniyxsq";

export const HEADER_NAVIGATION = {
  Home: "home",
  About: "about",
  Services: "services",
  // Testimonials: "testimonials",
  "Contact Us": "contact",
  Location: "location",
};

export const scrollToSection = (id) => {
  const element = document.getElementById(id);
  document.getElementById("menu-btn").checked = false;
  if (element) {
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  }
};

const BtlImages = [
  "minified/BTL/zcaiyn5ndz75sqbopwbk", //facebook
  "minified/BTL/shrvlv2rhpbdjxxdumfl", //indesit
  "minified/BTL/oflfqixwcjzwvwpv6bem", //ecco
  "minified/BTL/tk8miyrybje7bp06uu9z", //supergeneral
  "minified/BTL/zmdhjqnw0x5xwijefko", //pink
  "minified/BTL/sxwjj2wlkzfuqy8p1lzn", //brown
  "minified/BTL/lgct7jvtss9apx9muzrb", //sharp
  "minified/BTL/ahg2olg100zcgrwras3x", //sharp
  "minified/BTL/vvuookewnqlwumabferk", //samsung
  "minified/BTL/a7ccszxq9wvoqjqk3vc6", //samsung
];

const CorporateInteriorsImages = [
  "minified/Corporate interior/gnm9yx5j91wzxjywt9kf", //bread
  "minified/Corporate interior/iwua3y6vhggrvboh9uli", //jewlery
  "minified/Corporate interior/fuyofxg6jcnhhciyip2t", //mosaic
  "minified/Corporate interior/tr6y2piiv7scbxav5epb", //huawei
  "minified/Corporate interior/yhuvaxomgwhdkclqghp7", //sharp
  "minified/Corporate interior/gipgcsr7fzesi38qcae0", //sharp
  "minified/Corporate interior/zzskg9cyhyeggfwkqb78", //sharp
  "minified/Corporate interior/i6p1alqqupigqobebbt1", //sharp
  "minified/Corporate interior/j74sbfx9x1hqudt5slf9", //sony
  "minified/Corporate interior/zjwy4r1ktt5ap325jkaj", //sony
  "minified/Corporate interior/nmrommghomufugibxedc", //sony
  "minified/Corporate interior/t600zfwobb6qoofdswfq", //sony
  "minified/Corporate interior/jpce8mnxp24eilrh8azw", //sony
];

const ExhibitionStandsImages = [
  "minified/Exhibition stand/unanofbetw4qbb9s8rxk", //rebif
  "minified/Exhibition stand/pfwbz1sgul2lxzbfnysx", //louis dreyfus
  "minified/Exhibition stand/lkxbxt8hzw50f7l2zdnl", //solite
  "minified/Exhibition stand/yr7ythmzea8vrwiv4a3r", //idis
  "minified/Exhibition stand/jrfsv1zq2oulr5sqvwa7", //port of duqm
  "minified/Exhibition stand/bgms8g1wzquatefwpbvy", //rak islamic bank
  "minified/Exhibition stand/onkuv5vv3sggojotiadm", //serene
  "minified/Exhibition stand/lgeioupyh3mgfht8r307", //serene
  "minified/Exhibition stand/a00kzm4spj44ke3ufole", //sony
  "minified/Exhibition stand/tmunbbrm7dgwrfqjnd1x", //sony
  "minified/Exhibition stand/stw3gxqrtx6twqolcafo", //sony
];

const OffsetDigitalImages = [
  "minified/Offset _ digital/prmqcvrz9jgso3mrpsrj", //ac helmet
  "minified/Offset _ digital/lvpafzib1ulmobaileur", //monini
  "minified/Offset _ digital/hriawpdcnh4w8dpyay2u", //monday
  "minified/Offset _ digital/rntzamff7pgkbf1xafa9", //sadia
  "minified/Offset _ digital/cfifgjszul9zm0cnyiij", //electrolux
  "minified/Offset _ digital/efb3jgdwt1mg5qduivym", //fumo
  "minified/Offset _ digital/u1plntfvclbjmmiclouv", //blue
  "minified/Offset _ digital/ys9ceuztfr9q0hslidvk", //sapcemax
  "minified/Offset _ digital/skdfkze8c6q0hzojogyf", //instax
  "minified/Offset _ digital/uh9y2wnidljansjvialu", //sharp
  "minified/Offset _ digital/aqgczwfynlpbchwc6s0j", //glass
  "minified/Offset _ digital/omoxrcmjblhfdfhmuxoc", //femfresh
  "minified/Offset _ digital/zdha0psi8v6gjqkesqcn", //live well
  "minified/Offset _ digital/satz9zwziqclpadipmev", //grill
  "minified/Offset _ digital/a4m9wkvq74glkmhmns1a", //daewoo
  "minified/Offset _ digital/ayyldrbhyi4wjf903ugo", //daewoo
  "minified/Offset _ digital/ubil4rcplfi1bzu6lwpv", //daewoo
  "minified/Offset _ digital/ty7zkuinbxa7xez0ymic", //daewoo
  "minified/Offset _ digital/snwzdixdwu6hudfnvoj9", //haier
  "minified/Offset _ digital/bplfimmi7rri8eyydqga", //haier
  "minified/Offset _ digital/b2vpkmv8n6o75cxvr2kc", //haier
  "minified/Offset _ digital/sjhtnlqygovaelmqkqkl", //haier
];
const PosmImages = [
  "minified/POSM/c7lmefpkxhpaqbsdr9sr", //femfresh
  "minified/POSM/jachv26vn748rly9tx3f", //quality yellow
  "minified/POSM/xzdqtblgd6nozhuw56df", //evaporated milk
  "minified/POSM/ft3bj96tt94z886wnam4", //kitco
  "minified/POSM/kcisaddv3si0wpcavpke", //pate
  "minified/POSM/tougotqybe8vcyihn2wn", //fantastick
  "minified/POSM/krjdxiw8sdgbdezt6nde", //jacobs
  "minified/POSM/ogashtzssxzlrpkih3b2", //snuggy
  "minified/POSM/m6c5qcqj69e1ub4umqx7", //plastic container
  "minified/POSM/pgim6apday4bxfcdmhqc", //mentos
  "minified/POSM/v8eghg2rj9ui3631vjwv", //carrefour
  "minified/POSM/eqrbmsfjjhh2tiljcexk", //almarai
  "minified/POSM/ogqottuarxcyzzelpqwr", //batiste
  "minified/POSM/tofwcaowhewrgpnrcfgq", //wow
  "minified/POSM/ffhnbkfcxzvrhm9hhhbs", //wow
  "minified/POSM/uf04osendewxuh1aakoa", //fumo
  "minified/POSM/vflafj7kkklux3aqxwqi", //fumo
  "minified/POSM/zy84hgmspvyvbtu8vusk", //alain
  "minified/POSM/ye56mlxnutolgv4if9qu", //alain
];

const RetailStoreInterior = [
  "minified/Retail store interior/uf9l5yijbgzcjoz0ayp1", //ROG
  "minified/Retail store interior/npzradh082shquz7cta5", //nokia
  "minified/Retail store interior/ur87zcoe5sba8df4rnqq", //frigidaire
  "minified/Retail store interior/awkabh2kvjhnjtkupqbb", //indesit
  "minified/Retail store interior/lafgirwzhbr1kt1jitkb", //fifa
  "minified/Retail store interior/bork4loswguivthluvgy", //carrefour
  "minified/Retail store interior/xogtvck69parlpaziykm", //unioncoop
  "minified/Retail store interior/fsjy1cpus7hq65igekvg", //white shelf
  "minified/Retail store interior/xfsnbtjckoephvg541kk", //electrolux
  "minified/Retail store interior/hlmb8mkl1kvsvqrpj5oh", //electrolux
  "minified/Retail store interior/z6mh1twdlezqgdvhbqfx", //samsung
  "minified/Retail store interior/mx2esr2npjc8worcfq4r", //samsung
];

const SignageImages = [
  "minified/Signage/j8tjhauktq402v4kndmv", //garments
  "minified/Signage/aohgjpd0tfolsujkw1lv", //fancy&toys
  "minified/Signage/we6sgbduugqlzafqurqr", //fumo
  "minified/Signage/dm7gniql7tfoppkdjdkx", //fumo
  "minified/Signage/nphddxidseut3njces3d", //fumo
  "minified/Signage/aiogxos5abrj5oziaexf", //energyclub
  "minified/Signage/a21acdzum6wcf3iserkt", //energyclub
  "minified/Signage/xo2ucxospputgw81qdvj", //energyclub
];

const CorporateGiftsImages = [
  "minified/Corporate gifts 2/lvny5ej1d7ro1acg64hc", //gift card
  "minified/Corporate gifts 2/ca6nls5sgc6pu4zabtmp", //bag
  "minified/Corporate gifts 2/aaxsvwnzclz33cadpwtd", //bag
  "minified/Corporate gifts 2/heafh4lko3xfqwc99nrr", //bag
  "minified/Corporate gifts 2/hgou3xubdbx3qiusd8nw", //usb
  "minified/Corporate gifts 2/e2sdbgfrzkel17pwli9w", //usb
  "minified/Corporate gifts 2/qppyz54ys4rcaazt7qtd", //jacket&cap
  "minified/Corporate gifts 2/prv8nvl5q56itxcjndrb", //cap
  "minified/Corporate gifts 2/iy9cnma0sle3cn0ngi4w", //book
  "minified/Corporate gifts 2/sdkneym2xda133rnd97q", //book
  "minified/Corporate gifts 2/ct2xfe0gloijtghkgeuc", //t-shirt
  "minified/Corporate gifts 2/pbludbmf7kys7nmwl3m7", //t-shirt
  "minified/Corporate gifts 2/ezd375cpp6riitpfpkp3", //t-shirt
  "minified/Corporate gifts 2/d3db4cf7w0w2nxipoavq", //t-shirt
  "minified/Corporate gifts 2/dirhrmas8cxhb5bkxzk2", //t-shirt
];

export const HOME = [
  "minified/heading/smoxq0igw2iebgelmqia",
  "minified/heading/ls062unu2tik0riesqfr",
  "minified/heading/gaptzorg5admuyth2h6k",
  "minified/heading/qiwjqm4rm4o2wi2ck2ra",
  "minified/heading/gknbxqm3id2jezabbtyr",
  "minified/heading/g62emnepk9mzxrxikvwv",
  "minified/heading/hwybo0n3mmp33kw9eoau",
  "minified/heading/nc91zayqdyxzizx9faxs",
];

export const CASCADING = [
  { title: "Corporate Interiors", link: "sub_corporate_interior" },
  { title: "Offset/Digital Printing", link: "sub_offset_digital_printing" },
  { title: "Retail Store Invteriors", link: "sub_retail_store_interior" },
  { title: "BTL", link: "sub_btl" },
  { title: "Exhibition Stands", link: "sub_exhibition_stands" },
  { title: "POSM", link: "sub_posm" },
  { title: "Signage", link: "sub_signage" },
  { title: "Corporate Gifts", link: "sub_corporate_gifts" },
];

export const TESTIMONIALS = [
  "Working with MARQ52 has been a game-changer for our business. Their innovative strategies and unwavering commitment to our success have significantly boosted our online presence. Our revenue has seen a remarkable increase, and we couldn't be happier with the results. The MARQ52 team is not just a service provider; they're true partners in our growth journey. - John Doe, CEO, XYZ Tech Solutions.",
  "Partnering with MARQ52 has been a seamless experience. Their team's collaborative approach and dedication to understanding our unique needs have set them apart. The tailored marketing solutions they provided not only exceeded our expectations but also aligned perfectly with our brand identity. We're grateful for the positive impact they've had on our brand awareness. - Jane Smith, Marketing Director, ABC Retail Innovations.",
  "Choosing MARQ52 was one of the best decisions we made for our marketing strategy. Their team's deep expertise and constant innovation have transformed the way we connect with our audience. From social media campaigns to SEO optimization, their comprehensive approach has elevated our brand to new heights. We highly recommend MARQ52 for any company seeking cutting-edge marketing solutions. - Alex Johnson, Founder, XYZ Online Services.",
  "Results speak louder than words, and MARQ52 delivers. Their data-driven strategies and attention to detail have significantly increased our conversion rates. We've seen a substantial return on investment in a short period. It's refreshing to work with a marketing partner that is as committed to our success as we are. - Emily Davis, COO, ABC Manufacturing Solutions.",
  "What sets MARQ52 apart is not just their expertise but also their exceptional customer service. The team is always responsive, addressing our concerns promptly and providing valuable insights. Their support has been invaluable in navigating the dynamic landscape of digital marketing. We're confident that with MARQ52, we have a partner who genuinely cares about our success. - Mark Anderson, CEO, XYZ Consulting Group.",
];

export const ABOUT = [
  {
    heading: "Our Promise",
    title:
      "Our experienced and creative interior designers will craft a design that features your brand image and style, while meeting your requirements in terms of budget and timeline. We work closely with our clients to make sure they’re updated through every step in the entire process, right from the start up to the project’s completion and handover.",
  },
  {
    heading: "What We Offer",
    title:
      "We can create stands of all shapes and sizes, from the smallest to the grandest one. We have the capability to work with any materials and ensure high-quality results with out team of professionals having in-depth knowledge in the industry.",
  },
];

export const SERVICES = [
  {
    title: "Corporate Interior",
    text: "At our marketing company, we understand the profound impact of a thoughtfully designed corporate interior on both brand identity and employee productivity. Our approach to crafting compelling corporate interiors goes beyond aesthetics, integrating strategic marketing elements into the very fabric of the workspace. From meticulously curated color schemes that align with brand values to strategically placed visual elements that tell the story of our clients' success, every design choice is a deliberate nod to effective marketing. We prioritize creating spaces that not only reflect our clients' unique identities but also serve as dynamic platforms for fostering creativity, collaboration, and innovation – essential elements in today's competitive business landscape.",
    images: CorporateInteriorsImages,
    ordering: true,
    id: "sub_corporate_interior",
  },
  {
    title: "Offset/Digital Printing",
    text: "At our marketing company, we understand the profound impact of a thoughtfully designed corporate interior on both brand identity and employee productivity. Our approach to crafting compelling corporate interiors goes beyond aesthetics, integrating strategic marketing elements into the very fabric of the workspace. From meticulously curated color schemes that align with brand values to strategically placed visual elements that tell the story of our clients' success, every design choice is a deliberate nod to effective marketing. We prioritize creating spaces that not only reflect our clients' unique identities but also serve as dynamic platforms for fostering creativity, collaboration, and innovation – essential elements in today's competitive business landscape.",
    images: OffsetDigitalImages,
    ordering: false,
    id: "sub_offset_digital_printing",
  },
  {
    title: "Retail Store Interiors",
    text: "At our marketing company, we understand the profound impact of a thoughtfully designed corporate interior on both brand identity and employee productivity. Our approach to crafting compelling corporate interiors goes beyond aesthetics, integrating strategic marketing elements into the very fabric of the workspace. From meticulously curated color schemes that align with brand values to strategically placed visual elements that tell the story of our clients' success, every design choice is a deliberate nod to effective marketing. We prioritize creating spaces that not only reflect our clients' unique identities but also serve as dynamic platforms for fostering creativity, collaboration, and innovation – essential elements in today's competitive business landscape.",
    images: RetailStoreInterior,
    ordering: true,
    id: "sub_retail_store_interior",
  },
  {
    title: "BTL",
    text: "At our marketing company, we understand the profound impact of a thoughtfully designed corporate interior on both brand identity and employee productivity. Our approach to crafting compelling corporate interiors goes beyond aesthetics, integrating strategic marketing elements into the very fabric of the workspace. From meticulously curated color schemes that align with brand values to strategically placed visual elements that tell the story of our clients' success, every design choice is a deliberate nod to effective marketing. We prioritize creating spaces that not only reflect our clients' unique identities but also serve as dynamic platforms for fostering creativity, collaboration, and innovation – essential elements in today's competitive business landscape.",
    images: BtlImages,
    ordering: false,
    id: "sub_btl",
  },
  {
    title: "Exhibition Stands",
    text: "At our marketing company, we understand the profound impact of a thoughtfully designed corporate interior on both brand identity and employee productivity. Our approach to crafting compelling corporate interiors goes beyond aesthetics, integrating strategic marketing elements into the very fabric of the workspace. From meticulously curated color schemes that align with brand values to strategically placed visual elements that tell the story of our clients' success, every design choice is a deliberate nod to effective marketing. We prioritize creating spaces that not only reflect our clients' unique identities but also serve as dynamic platforms for fostering creativity, collaboration, and innovation – essential elements in today's competitive business landscape.",
    images: ExhibitionStandsImages,
    ordering: true,
    id: "sub_exhibition_stands",
  },
  {
    title: "POSM",
    text: "At our marketing company, we understand the profound impact of a thoughtfully designed corporate interior on both brand identity and employee productivity. Our approach to crafting compelling corporate interiors goes beyond aesthetics, integrating strategic marketing elements into the very fabric of the workspace. From meticulously curated color schemes that align with brand values to strategically placed visual elements that tell the story of our clients' success, every design choice is a deliberate nod to effective marketing. We prioritize creating spaces that not only reflect our clients' unique identities but also serve as dynamic platforms for fostering creativity, collaboration, and innovation – essential elements in today's competitive business landscape.",
    images: PosmImages,
    ordering: false,
    id: "sub_posm",
  },

  {
    title: "Signage",
    text: "At our marketing company, we understand the profound impact of a thoughtfully designed corporate interior on both brand identity and employee productivity. Our approach to crafting compelling corporate interiors goes beyond aesthetics, integrating strategic marketing elements into the very fabric of the workspace. From meticulously curated color schemes that align with brand values to strategically placed visual elements that tell the story of our clients' success, every design choice is a deliberate nod to effective marketing. We prioritize creating spaces that not only reflect our clients' unique identities but also serve as dynamic platforms for fostering creativity, collaboration, and innovation – essential elements in today's competitive business landscape.",
    images: SignageImages,
    ordering: true,
    id: "sub_signage",
  },

  {
    title: "Corporate Gifts",
    text: "At our marketing company, we understand the profound impact of a thoughtfully designed corporate interior on both brand identity and employee productivity. Our approach to crafting compelling corporate interiors goes beyond aesthetics, integrating strategic marketing elements into the very fabric of the workspace. From meticulously curated color schemes that align with brand values to strategically placed visual elements that tell the story of our clients' success, every design choice is a deliberate nod to effective marketing. We prioritize creating spaces that not only reflect our clients' unique identities but also serve as dynamic platforms for fostering creativity, collaboration, and innovation – essential elements in today's competitive business landscape.",
    images: CorporateGiftsImages,
    ordering: false,
    id: "sub_corporate_gifts",
  },
];

export const animationDuration = (width) => (width < 800 ? 3000 : 4000);

export const LOCATION = [
  {
    default: true,
    tabBtnName: "London",
    bckg: "/london.jpeg",
    locationName: "London",
    mobileName: "+447585383197",
  },
  {
    default: false,
    tabBtnName: "Dubai",
    bckg: "/dubai.jpeg",
    locationName: "Dubai",
    mobileName: "+971555742707",
  },
];
